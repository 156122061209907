@charset "utf-8";

body {
  font-family: 'Nanum Gothic', 'Malgun Gothic', 'dotum', '돋움', 'gulim', '굴림', arial !important;
}

@font-face {
  font-family: 'Nanum Square';
  font-weight: 400;
  src: url(./font/NanumSquareR.eot);
  src: url(./font/NanumSquareR.eot?#iefix) format('embedded-opentype'),
    url(./font/NanumSquareR.woff) format('woff'),
    url(./font/NanumSquareR.ttf) format('truetype');
}

@font-face {
  font-family: 'Nanum Square';
  font-weight: 600;
  src: url(./font/NanumSquareB.eot);
  src: url(./font/NanumSquareB.eot?#iefix) format('embedded-opentype'),
    url(./font/NanumSquareB.woff) format('woff'),
    url(./font/NanumSquareB.ttf) format('truetype');
}

@font-face {
  font-family: 'Nanum Square';
  font-weight: 800;
  src: url(./font/NanumSquareEB.eot);
  src: url(./font/NanumSquareEB.eot?#iefix) format('embedded-opentype'),
    url(./font/NanumSquareEB.woff) format('woff'),
    url(./font/NanumSquareEB.ttf) format('truetype');
}

@font-face {
  font-family: 'Nanum Square';
  font-weight: 300;
  src: url(./font/NanumSquareL.eot);
  src: url(./font/NanumSquareL.eot?#iefix) format('embedded-opentype'),
    url(./font/NanumSquareL.woff) format('woff'),
    url(./font/NanumSquareL.ttf) format('truetype');
}

//팝업
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  padding: 40px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #fff;
  z-index: 9999;

  .popHead {
    padding-bottom: 10px;

    .title {
      font-family: "Nanum Square";
      font-size: 20px;
      font-weight: 800;
      text-align: left;
      color: #000;
    }

    .btn.close {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 40px;
      height: 40px;
      padding: 0;
      background: url(../images/common/icon-popup-close.png) no-repeat center;
      border: 0;
      cursor: pointer;
      z-index: 10;
    }
  }


  .popCont {
    &.eng {
      .writeForm dl.write {
        grid-template-columns: 130px 1fr;
      }
    }
  }

  .writeForm {
    padding: 20px;
    border-radius: 6px;
    background-color: #f6f6f6;

    dl.write {

      display: grid;
      grid-template-columns: 85px 1fr;
      row-gap: 8px;
      margin: 0;

      dt {
        display: flex;
        align-items: center;
        min-height: 40px;
        font-family: 'Nanum Square';
        font-size: 14px;
        font-weight: bold;
        text-align: left;
        color: #000;
      }

      dd {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;

        input {
          width: 100%;
          max-width: 240px;
          height: 40px;
          padding: 8px;
          font-family: 'Nanum Square';
          border: solid 1px #ddd;
          background-color: #fff;
          box-sizing: border-box;
        }

        textarea {
          width: 100%;
          min-height: 80px;
          padding: 8px;
          font-family: 'Nanum Square';
          border: solid 1px #ddd;
          background-color: #fff;
          box-sizing: border-box;
          resize: none;
        }
      }
    }
  }

  dl.gray {
    display: grid;
    border-top: solid 1px #d2d2d2;
    border-bottom: solid 1px #d2d2d2;
    margin: 0;

    dt {
      grid-row: 1;
      height: 32px;
      padding: 8px;
      background-color: #f6f6f6;
      box-sizing: border-box;
      font-family: "Nanum Square";
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      color: #000;
    }

    dd {
      display: flex;
      align-items: center;
      grid-row: 2;
      padding: 8px;
      margin: 0;
      background-color: #fff;
      box-sizing: border-box;
      font-family: "Nanum Square";
      font-size: 14px;
      text-align: left;
      color: #444;
    }

  }

  .terms {
    margin: 30px 0;

    .termHead {
      .btn.toggle {
        display: none;
        position: absolute;
        right: 0;
        bottom: 8px;
        min-width: 24px;
        width: 24px;
        height: 24px;
        padding: 0;
        background: #fff url(../images/common/icon-popup-arrow.png) no-repeat center;
        border: 0;
        cursor: pointer;
      }
    }

    .grayText {
      margin: 10px 0 0 0;
      font-family: 'Nanum Square';
      font-size: 13px;
      text-align: left;
      color: #666;
    }

    .checkbox {
      margin-bottom: 10px;
    }
  }

  .btnArea {
    text-align: center;

    .btn.blue {
      display: inline-block;
      min-width: 120px;
      height: 40px;
      padding: 8px 16px;
      box-sizing: border-box;
      background-color: #34428b;
      font-family: 'Nanum Square';
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      line-height: 25px;
      color: #fff;
      text-decoration: none;
    }
  }
}


// overlay
.overlay {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7) repeat;
}

//체크박스
.checkbox {
  display: inline-block;
  cursor: pointer;
  margin: 0;

  &:last-child {
    margin-right: 0;
  }

  input[type="checkbox"] {
    position: absolute;
    display: inline-block;
    width: 18px;
    height: 18px;
    margin: 0 -1px !important;
    visibility: hidden;
    line-height: 0;
  }

  label {
    position: relative;
    display: inline-block;
    min-height: 20px;
    padding-left: 26px !important;
    font-family: 'Nanum Square';
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    color: #000;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: 18px;
      height: 18px;
      background-color: #34428b;
      border-radius: 4px;
      vertical-align: middle;
    }

    .red {
      color: #da3030;
    }
  }

  input[type="checkbox"]:checked+label {
    &:before {
      background: url("../images/common/icon-checkbox.png") no-repeat center;
    }
  }
}


@media screen and (max-width: 767px) {

  //팝업
  .popup {
    width: 328px;
    padding: 20px 16px;

    .popHead {
      padding-bottom: 10px;

      .title {
        font-size: 18px;
      }

      .btn.close {
        right: 0;
      }
    }

    .popCont {
      max-height: 420px;
      overflow-y: auto;

      &.eng {
        .writeForm dl.write {
          grid-template-columns: 1fr;
        }

        .checkbox {
          label {
            padding-left: 0 !important;

            &::before {
              position: initial;
              display: block;
              margin-bottom: 8px;
            }
          }
        }
      }
    }

    .writeForm {
      padding: 16px;

      dl.write {
        grid-template-columns: 1fr;
        row-gap: 0;

        dt {
          min-height: initial;

          +dd {
            margin-top: 4px;
          }
        }

        dd {
          input {
            max-width: initial;
          }

          +dt {
            margin-top: 10px;
          }
        }
      }
    }

    dl.gray {
      display: grid;
      margin: 0;

      dt {
        grid-row: initial;
        min-height: 32px;
      }

      dd {
        grid-row: initial;
        min-height: 32px;
      }

    }

    .terms {
      margin: 20px 0;

      .termHead {
        position: relative;
        min-height: 40px;
        padding-right: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4px;

        .btn.toggle {
          display: block;
        }
      }

      .termCont {
        display: none;
      }

      &.on {
        .termHead {
          .btn.toggle {
            transform: rotate(180deg);
          }
        }

        .termCont {
          display: block;
        }
      }

      .grayText {
        font-size: 14px;
      }

      .checkbox {
        margin-bottom: 0;
      }
    }
  }
}